import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../service/user.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
  providers: [UserService, NgxSpinnerService]
})
export class ChangePasswordComponent implements OnInit {


  public model: any = {};
  public data: any = {};
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private service: UserService,
    private toastr: ToastrService,

  ) { }

  ngOnInit() {
    this.data = JSON.parse(localStorage.getItem('currentUser'));

  }

  onSubmit(f: NgForm) {

    console.log(this.data.token);

    let reset = {
      "password": this.model.Password,
      "token": this.data.token
    }

    this.spinner.show();
    if (this.model.Password == this.model.ConfirmPassword) {

      this.service.changePassword(reset)
        .subscribe(
          data => {
            this.spinner.hide();
            if (data.responseCode == 200) {
              f.resetForm();
              this.toastr.success(data.responseData);
            }
            else {
              this.spinner.hide();
              this.toastr.error(data.responseData);
            }

          },
          error => {
            // this.error = error;

          });
    }
    else {
      this.spinner.hide();
      this.toastr.info('confirm password must be same as password');
    }
  }

}
