import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css'],
  providers: [UserService]
})
export class NavMenuComponent implements OnInit {

  isExpanded = false;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private service: UserService,

  ) { }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  Logout() {
    this.service.logout();
    this.router.navigate(['/']);
  }

  ngOnInit() {
  }

}
