import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';
  public model: any = {};

  constructor(
    private route: ActivatedRoute,
    private router: Router, private spinner: NgxSpinnerService,
    private service: UserService,
    private toastr: ToastrService,
  ) { }


  ngOnInit() {
  }
  onSubmit() {
    this.spinner.show();
    let code = this.route.snapshot.queryParamMap.get('id');
    let reset = {
      "password": this.model.Password,
      "token": code
    }
    if (this.model.Password == this.model.ConfirmPassword) {

      this.service.resetPassword(reset)
        .subscribe(
          data => {
            this.spinner.hide();
            if (data.responseCode == 200) {
              this.toastr.success(data.responseData);
              setTimeout(() => {
                this.router.navigateByUrl('/');
              }, 5000);

            } else {
              this.spinner.hide();
              this.toastr.error(data.responseData);
            }
          },
          error => {
            // this.error = error;

          });

    }
    else {
      this.spinner.hide();
      this.toastr.info('confirm password must be same as password');
    }

  }
}
