import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from "ngx-spinner";
import { debug } from 'util';
import { UserService } from '../service/user.service';
import { CookieService } from 'ngx-cookie-service';
import * as CryptoJS from 'crypto-js';
import { encrypt, decrypt } from '../_helpers/jwt.interceptor';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [UserService, NgxSpinnerService]
})

export class LoginComponent implements OnInit {


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private service: UserService,
    private spinner: NgxSpinnerService,
    private cookieService: CookieService
  ) { }

  cookieExists: boolean;
  cookieValue = 'UNKNOWN';
  loginForm: FormGroup;
  returnUrl: string;
  public model: any = {};

  ngOnInit() {
    this.cookieExists = this.cookieService.check('Email');
    if (localStorage.getItem('currentUser') != null) {
      this.router.navigate(['/Dashboard']);
    }
    else {
      this.router.navigate(['/Login']);
      if (this.cookieExists) {
        this.model.Email = decrypt(this.cookieService.get('Email')) ;
        this.model.Password = decrypt(this.cookieService.get('Password')) ;
        this.model.remember = this.cookieService.get('Remember') ;
      }
    }
  }

  
  onSubmit(f: NgForm) {
    this.spinner.show();
    if (this.model.remember) {
      this.cookieService.set('Email', encrypt(this.model.Email));
      this.cookieService.set('Password', encrypt(this.model.Password));
      this.cookieService.set('Remember', encrypt(this.model.remember));
      // this.cookieValue = this.cookieService.get('Test');
    }
    else{
      this.cookieService.deleteAll();
    }

    this.service.login(this.model)
      .subscribe(
        data => {
          this.spinner.hide();
          if (data.responseCode == 200) {
            this.router.navigate(['/Dashboard']);
          } else {

            this.toastr.error(data.responseData);
          }
        },
        error => {
          this.spinner.hide();
          // this.error = error;

        });
  }
}
