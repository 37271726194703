import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { PodcastTopicComponent } from './podcast-topic/podcast-topic.component';
import { PodcastComponent } from './podcast/podcast.component';
import { AuthGuard } from './_gaurd/auth.guard';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { TestPgaeComponent } from './test-pgae/test-pgae.component';



const routes: Routes = [

  //{ path: '', component: LoginComponent, pathMatch: 'full' },
  { path: '', component: LandingPageComponent, pathMatch: 'full' },
  { path: 'Login', component: LoginComponent },
  { path: 'Dashboard', component: HomeComponent , canActivate: [AuthGuard]},
  { path: 'ForgotPassword', component: ForgotPasswordComponent },
  { path: 'ChangePassword', component: ChangePasswordComponent, canActivate: [AuthGuard] },
  { path: 'ResetPassword', component: ResetPasswordComponent },
  { path: 'PodcastTopic', component: PodcastTopicComponent , canActivate: [AuthGuard]},
  { path: 'Podcast', component: PodcastComponent, canActivate: [AuthGuard] },
  { path: 'PrivacyPolicy', component: PrivacyPolicyComponent },
  { path: '**', redirectTo: '' },
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }) ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
