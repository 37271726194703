import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle, CdkDropList } from '@angular/cdk/drag-drop';
import clonedeep from 'lodash.clonedeep';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { NgForm } from '@angular/forms';
import { UserService } from '../service/user.service';


export interface PeriodicElement {
  displayOrder: number;
  topicTitle: string;
  thumbnail: string;
  Action: string;
}
const ELEMENT_DATA: PeriodicElement[] = [];


@Component({
  selector: 'app-podcast-topic',
  templateUrl: './podcast-topic.component.html',
  styleUrls: ['./podcast-topic.component.css']
})


export class PodcastTopicComponent implements OnInit {
  modalRef: BsModalRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private service: UserService,
    private toastr: ToastrService,
    private modalService: BsModalService

  ) { }

  model: any = {};
  imageChangedEvent: any = '';
  croppedImage: any = '';
  ViewImage: any = '';
  id: any = 0;
  title: any = '';
  Htmloption: any = 'S';


  @ViewChild('table1', { static: false }) table1: MatTable<any>;
  @ViewChild('table2', { static: false }) table2: MatTable<any>;
  @ViewChild('list1', { static: false }) list1: CdkDropList;

  @ViewChild('myInput', { static: false }) myInputVariable: ElementRef;

  displayedColumns: string[] = ['DisplayOrder', 'Topic', 'Thumbnail', 'Action'];
  ELEMENT_DATA: PeriodicElement[];
  ELEMENT_DATA2: PeriodicElement[];

  dataSource = new MatTableDataSource<PeriodicElement>(ELEMENT_DATA);
  dataSource2;

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  ngOnInit() {
    this.ELEMENT_DATA2 = [
    ];
    this.getPodcastTopicList();

    console.log(this.ELEMENT_DATA);

    this.dataSource2 = new MatTableDataSource(this.ELEMENT_DATA2);
    this.dataSource.paginator = this.paginator;
    this.model.Id = 0;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }

    // updates moved data and table, but not dynamic if more dropzones
    this.dataSource.data = this.dataSource && this.dataSource.data ? clonedeep(this.dataSource.data) : [];
    this.dataSource2.data = this.dataSource2 && this.dataSource2.data ? clonedeep(this.dataSource2.data) : [];
    if (this.dataSource && this.dataSource.data) {
      this.dataSource.data.forEach((value, key) => {
        this.dataSource.data[key].displayOrder = key + 1;
      });
    }

    console.log('this.dataSource.data', this.dataSource.data);
    this.updateDisplayorder(this.dataSource.data);
  }

  updateDisplayorder(lst) {
    console.log('updateDisplayorder', lst);
    this.service.updateDisplayOrder(lst)
      .subscribe(
        data => {
          this.spinner.hide();
          if (data.responseCode == 200) {
            this.getPodcastTopicList();
            //this.ELEMENT_DATA = data.responseData;
            //console.log('this.ELEMENT_DATA', this.ELEMENT_DATA);
            //this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
          }
          else {
            this.spinner.hide();
            this.toastr.error(data.responseData, '');
          }

        },
        error => {
          // this.error = error;

        });
  }

  applyFilter(filterValue: string) {
    //this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  fileChangeEvent(event: any): void {
    var file = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
    var pattern = /image-*/;

    if (!file.type.match(pattern)) {
      this.toastr.error("Invalid Format", '');
      file = "";
      return;
    } else {
      this.imageChangedEvent = event;
    }

  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    this.model.Thumbnail = this.croppedImage;
  }

  imageLoaded() {
    // show cropper
  }

  cropperReady() {
    // cropper ready
  }

  loadImageFailed() {
    // show message
  }


  getPodcastTopicList() {
    this.spinner.show();
    this.service.getPodcastTopicList()
      .subscribe(
        data => {
          this.spinner.hide();
          if (data.responseCode == 200) {

            this.ELEMENT_DATA = data.responseData;
            console.log('this.ELEMENT_DATA', this.ELEMENT_DATA);
            this.dataSource = new MatTableDataSource(this.ELEMENT_DATA);
            this.dataSource.paginator = this.paginator;
          }
          else {
            this.spinner.hide();
            this.toastr.error(data.responseData, '');
          }

        },
        error => {
          // this.error = error;

        });

  }

  onSubmit(f: NgForm) {
    this.spinner.show();
    console.log(this.model);
    if (this.model.Id == undefined)
      this.model.Id = 0;

    
    if (this.model.Id == 0) {
      if (this.model.Thumbnail != null) {
        this.service.createPodcastTopic(this.model)
          .subscribe(
            data => {
              this.spinner.hide();
              if (data.responseCode == 200) {
                this.toastr.success(data.responseData);
                this.getPodcastTopicList();
                this.reset(f);
                this.model = {};


              }
              else {
                this.spinner.hide();
                this.toastr.error(data.responseData, '');
                this.reset(f);
                this.model = {};
              }

            },
            error => {
              // this.error = error;
              this.spinner.hide();
            });
      }
      else {
        this.spinner.hide();
        this.toastr.error("Image cannot be blank");
      }
    }
    else {
      this.service.updatePodcastTopic(this.model)
        .subscribe(
          data => {
            this.spinner.hide();
            if (data.responseCode == 200) {
              this.toastr.success(data.responseData);
               
              this.getPodcastTopicList();
              this.reset(f);
              this.model = {};
            }
            else {
              this.spinner.hide();
              this.toastr.error(data.responseData, '');
              this.reset(f);
              this.model = {};
            }
          },
          error => {
            // this.error = error;
            this.spinner.hide();
          });
    }


  }

  openModal(template: TemplateRef<any>, rowData) {
    this.modalRef = this.modalService.show(template);
    this.ViewImage = " Images/" + rowData.thumbnail;
    this.title = rowData.topicTitle;
  }

  EditPodcastTopic(id) {
    this.service.getPodcastTopicByID(id)
      .subscribe(
        data => {
          this.spinner.hide();
          if (data.responseCode == 200) {
            console.log(data);
            this.model.Id = data.responseData.id;
            this.model.DisplayOrder = data.responseData.displayOrder
            this.model.TopicTitle = data.responseData.topicTitle;
            this.croppedImage = "Images/" + data.responseData.thumbnail;
            this.model.Thumbnail = data.responseData.thumbnail;
            this.model.AddedOnUtc = data.responseData.addedOnUtc;

          }
          else {
            this.spinner.hide();
            this.toastr.error(data.responseData, '');
          }

        },
        error => {
          // this.error = error;

        });
  }

  openDeleteModal(template: TemplateRef<any>, id) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.id = id;
  }

  confirm(id): void {
    //this.message = 'Confirmed!';
    this.service.deletePodcastTopic(id)
      .subscribe(
        data => {
          this.spinner.hide();
          if (data.responseCode == 200) {
            this.toastr.success(data.responseData);
            this.getPodcastTopicList();
          }
          else {
            this.spinner.hide();
            this.toastr.error(data.responseData, '');
          }

        },
        error => {
          // this.error = error;

        });
    this.modalRef.hide();
  }

  decline(): void {
    // this.message = 'Declined!';
    this.modalRef.hide();
  }

  reset(f: NgForm) {

    f.resetForm();
    setTimeout(() => {

      this.model.topicId = "";
      this.myInputVariable.nativeElement.value = "";
      this.croppedImage = '';
      this.imageChangedEvent = '';
    })


  }
}
