import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css'],
  providers: [UserService, NgxSpinnerService]
})
export class ForgotPasswordComponent implements OnInit {
  loginForm: FormGroup;

  public model: any = {};


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private service: UserService,
    private toastr: ToastrService,

  ) { }
  

  ngOnInit() {
  }
  onSubmit() {
    this.spinner.show();
    this.service.forgotPassword(this.model)
      .subscribe(
        data => {
          this.spinner.hide();
          if (data.responseCode == 200) {
            this.toastr.success(data.responseData);
            setTimeout(() => {
              this.router.navigateByUrl('/');
            }, 5000);

          }
          else {
            this.toastr.error(data.responseData);
          }

        },
        error => {
          // this.error = error;

        });
  }
}
