
import { Component, OnInit, ViewChild, TemplateRef, ElementRef } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource, MatTable } from '@angular/material/table';
import { CdkDragDrop, moveItemInArray, transferArrayItem, CdkDragHandle, CdkDropList } from '@angular/cdk/drag-drop';
import clonedeep from 'lodash.clonedeep';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { debug } from 'util';
import { NgForm } from '@angular/forms';
import { UserService } from '../service/user.service';



export interface PeriodicElement {
  displayOrder: number;
  topicTitle: string;
  file: string;
  Action: string;
}

export interface Topic {
  value: string;
  viewValue: string;
}


export interface Food {
  value: string;
  viewValue: string;
}
const Podcast_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-podcast',
  templateUrl: './podcast.component.html',
  styleUrls: ['./podcast.component.css'],
  providers: [UserService, NgxSpinnerService]
})
export class PodcastComponent implements OnInit {

  modalRef: BsModalRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private spinner: NgxSpinnerService,
    private service: UserService,
    private toastr: ToastrService,
    private modalService: BsModalService

  ) { }


  model: any = {};
  imageChangedEvent: any = '';
  croppedImage: any = '';
  ViewImage: any = '';
  title: any = '';
  id: any = 0;
  Htmloption: any = 'S';
  @ViewChild('table1', { static: false }) table1: MatTable<PeriodicElement>;
  @ViewChild('table2', { static: false }) table2: MatTable<PeriodicElement>;
  @ViewChild('list1', { static: false }) list1: CdkDropList;

  @ViewChild('myInput', { static: false }) myInputVariable: ElementRef;

  displayedColumns: string[] = ['DisplayOrder', 'Topic', 'File', 'Action'];
  Podcast_DATA: PeriodicElement[] = [];
  Podcast_DATA2: PeriodicElement[] = [];
  PodcastdataSource = new MatTableDataSource<PeriodicElement>(Podcast_DATA);
  dataSource2;
  renderedRows;
  topiclist: Topic[];
  foods: Food[] = [
    { value: 'steak-0', viewValue: 'Steak' },
    { value: 'pizza-1', viewValue: 'Pizza' },
    { value: 'tacos-2', viewValue: 'Tacos' }
  ];

  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator;

  ngOnInit() {
    this.model.topicId = "";
    //  this.PodcastdataSource = [];
    //this.Podcast_DATA = [
    //  { "displayOrder": 7, "topicTitle": 'Nitrogen', "file": null, "Action": 'N' },
    //  { "displayOrder": 8, "topicTitle": 'Oxygen', "file": null, "Action": 'O' },
    //  { "displayOrder": 9, "topicTitle": 'Fluorine', "file": null, "Action": 'F' },
    //  { "displayOrder": 10, "topicTitle": 'Neon', "file": null, "Action": 'Ne' },
    //];
    //this.PodcastdataSource = this.Podcast_DATA;

    /*temp*/
    //this.spinner.show();
    //this.service.getPodcastList()
    //  .subscribe(
    //    data => {
    //      this.spinner.hide();
    //      if (data.responseCode == 200) {
    //        console.log(data.responseData);

    //        this.Podcast_DATA = data.responseData;
    //        debugger;

    //        //this.Podcast_DATA = [
    //        //  { displayOrder: 7, topicTitle: 'Nitrogen', file: null, Action: 'N' },
    //        //  { displayOrder: 8, topicTitle: 'Oxygen', file: null, Action: 'O' },
    //        //  { displayOrder: 9, topicTitle: 'Fluorine', file: null, Action: 'F' },
    //        //  { displayOrder: 10, topicTitle: 'Neon', file: null, Action: 'Ne' },
    //        //];
    //        this.PodcastdataSource = this.Podcast_DATA;
    //        console.log('this.Podcast_DATA', this.Podcast_DATA);
    //        // this.PodcastdataSource = new MatTableDataSource(this.Podcast_DATA);
    //        console.log(this.PodcastdataSource);
    //      }
    //      else {
    //        this.spinner.hide();
    //        this.toastr.error(data.responseData);
    //      }

    //    },
    //    error => {
    //      // this.error = error;

    //    });

    /*temp end*/


    //this.dataSource2 = new MatTableDataSource(this.Podcast_DATA2);
    this.getPodcastList();

    //console.log(this.Podcast_DATA);
    // this.dataSource2 = new MatTableDataSource(this.Podcast_DATA2);
    this.model.Id = 0;
    this.getTopicList();


  }

  SetDragDropData(data: PeriodicElement[]) {
    this.PodcastdataSource.data = data;
  }

  drop(event: CdkDragDrop<PeriodicElement[]>) {
    console.log(event.item.data);
    const prevIndex = this.PodcastdataSource.data.findIndex((d) => d === event.item.data);

    moveItemInArray(this.PodcastdataSource.data, prevIndex, event.currentIndex);
    this.table1.renderRows();
    //if (event.previousContainer === event.container) {
    //  moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    //  this.table1.renderRows();
    //} else {
    //  transferArrayItem(event.previousContainer.data,
    //    event.container.data,
    //    event.previousIndex,
    //    event.currentIndex);
    //}

    // updates moved data and table, but not dynamic if more dropzones
    this.PodcastdataSource.data = this.PodcastdataSource.data ? clonedeep(this.PodcastdataSource.data) : [];
    //  this.dataSource2.data = this.dataSource2 && this.dataSource2.data ? clonedeep(this.dataSource2.data) : [];
    if (this.PodcastdataSource) {
      this.PodcastdataSource.data.forEach((value, key) => {
        this.PodcastdataSource.data[key].displayOrder = key + 1;
      });
    }

    console.log('this.dataSource.data', this.PodcastdataSource);
    // this.updateDisplayorder(_.cloneDeep(this.PodcastdataSource.data);
    this.updateDisplayorder(this.PodcastdataSource.data);
  }

  getPodcastList() {
    this.spinner.show();
    this.service.getPodcastList()
      .subscribe(
        data => {
          this.spinner.hide();
          if (data.responseCode == 200) {
            //console.log(data.responseData);

            this.Podcast_DATA = data.responseData;

            //this.SetDragDropData(data.responseData);
            //this.Podcast_DATA = [
            //  { displayOrder: 7, topicTitle: 'Nitrogen', file: null, Action: 'N' },
            //  { displayOrder: 8, topicTitle: 'Oxygen', file: null, Action: 'O' },
            //  { displayOrder: 9, topicTitle: 'Fluorine', file: null, Action: 'F' },
            //  { displayOrder: 10, topicTitle: 'Neon', file: null, Action: 'Ne' },
            //];
            this.PodcastdataSource.data = this.Podcast_DATA;
            this.PodcastdataSource.paginator = this.paginator;

            // this.PodcastdataSource.paginator = this.paginator;
            this.table1.renderRows();
            //console.log('this.Podcast_DATA', this.Podcast_DATA);
            // this.PodcastdataSource = new MatTableDataSource(this.Podcast_DATA);
            // console.log(this.PodcastdataSource);
          }
          else {
            this.spinner.hide();
            this.toastr.error(data.responseData, '');
          }

        },
        error => {
          // this.error = error;

        });

  }


  updateDisplayorder(lst) {
    console.log('updateDisplayorder', lst);
    this.service.updatePodcastDisplayOrder(lst)
      .subscribe(
        data => {
          this.spinner.hide();
          if (data.responseCode == 200) {
            this.getPodcastList();

          }
          else {
            this.spinner.hide();
            this.toastr.error(data.responseData, '');
          }

        },
        error => {
          // this.error = error;

        });
  }

  applyFilter(filterValue: string) {


    console.log(filterValue);
    if (filterValue == "Select All") {
      this.PodcastdataSource.filter = "";
      this.renderedRows = undefined;
      // this.PodcastdataSource.data=[];
      // this.getPodcastList();

    } else {
      this.PodcastdataSource.filter = filterValue.trim().toLowerCase();
      setTimeout(() => {
        try {
          this.renderedRows = document.getElementsByClassName("mat-row").length;
        } catch (e) {

        }

      })

    }


  }

  fileChangeEvent(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /audio-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      this.toastr.error("Invalid Format", '');
      file = "";
      return;
    }
    if (file.siz > 100000000) {
      this.toastr.error("File is too big. Maximum 100mb file can be allowed.", '');
      file = "";
      return;
    }
    this.model.files = file;
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e) {
    let reader = e.target;
    this.model.file = "";// reader.result;

  }

  getTopicList() {
    this.service.getPodcastTopicList()
      .subscribe(
        data => {
          this.spinner.hide();
          if (data.responseCode == 200) {

            this.topiclist = data.responseData;
            console.log('this.Podcast_DATA', this.topiclist);
            //this.dataSource = new MatTableDataSource(this.Podcast_DATA);
          }
          else {
            this.spinner.hide();
            this.toastr.error(data.responseData, '');
          }

        },
        error => {
          // this.error = error;

        });

  }

  onSubmit(f: NgForm) {
    this.spinner.show();

    if (this.model.Id == undefined)
      this.model.Id = 0;

    if (this.model.Id == 0) {
      if (this.model.file != null) {
        this.model.topicId = parseInt(this.model.topicId);
       // this.model.Id = parseInt(this.model.topicId);
        if (this.model.Id==0)
          this.model.Id = "";

        var fd = new FormData();
        fd.append("topicId", this.model.topicId);
        fd.append("files", this.model.files);
        fd.append("file", "");
        fd.append("description", this.model.Description);

        this.service.createPodcast(fd)
          .subscribe(
            data => {
              this.spinner.hide();
              if (data.responseCode == 200) {
                this.toastr.success(data.responseData);
                this.getPodcastList();
                this.reset(f);
                this.model = {};
              }
              else {
                this.spinner.hide();
                this.reset(f);
                this.toastr.error(data.responseData,'');
              }

            },
            error => {
              this.spinner.hide();
              // this.error = error;

            });
      }
      else {
        this.toastr.error("Audio File required", '');
        this.spinner.hide();
      }
    } else {
      this.model.Id = this.model.Id.toString();
      var fd = new FormData();
      if(this.model.file == "")
      {
        fd.append("files", this.model.files);
        fd.append("file", "");
      }
      else {
        fd.append("files", null);
        fd.append("file", this.model.file);
      }

      fd.append("Id", this.model.Id);
      fd.append("topicId", this.model.topicId);
      fd.append("description", this.model.Description);

      this.service.updatePodcast(fd)
        .subscribe(
          data => {
            this.spinner.hide();
            if (data.responseCode == 200) {
              this.toastr.success(data.responseData);
              this.getPodcastList();
              this.reset(f);
              this.model = {};
            }
            else {
              this.spinner.hide();
              this.reset(f);
              this.toastr.error(data.responseData, '');
            }
          },
          error => {
            // this.error = error;
            this.spinner.hide();
          });
    }
  }

  EditPodcastTopic(id) {
    this.service.getPodcastByID(id)
      .subscribe(
        data => {
          this.spinner.hide();
          if (data.responseCode == 200) {
            console.log(data);

            this.model.Id = data.responseData.id;
            this.model.DisplayOrder = data.responseData.displayOrder
            this.model.topicId = data.responseData.topicId;
            //this.croppedImage = "Audio/" + data.responseData.file;
            this.model.file = data.responseData.file;
            this.model.Description = data.responseData.description;

          }
          else {
            this.spinner.hide();
            this.toastr.error(data.responseData);
          }

        },
        error => {
          // this.error = error;
          this.spinner.hide();
        });
  }


  openDeleteModal(template: TemplateRef<any>, id) {
    this.modalRef = this.modalService.show(template, { class: 'modal-sm' });
    this.id = id;
  }

  confirm(id): void {
    //this.message = 'Confirmed!';
    this.service.deletePodcast(id)
      .subscribe(
        data => {
          this.spinner.hide();
          if (data.responseCode == 200) {
            this.toastr.success(data.responseData);
            this.getPodcastList();
          }
          else {
            this.spinner.hide();
            this.toastr.error(data.responseData, '');
          }

        },
        error => {
          // this.error = error;

        });
    this.modalRef.hide();
  }

  decline(): void {
    // this.message = 'Declined!';
    this.modalRef.hide();
  }

  reset(f: NgForm) {
    f.resetForm();
    setTimeout(() => {

      this.model.topicId = "";
      this.myInputVariable.nativeElement.value = "";
    })


  }
}
