import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { User } from './_models/User';
import { UserService } from './service/user.service';
import { BnNgIdleService } from 'bn-ng-idle';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'SoilHealth';
  currentUser: User;

  constructor(
    private router: Router,
    private authenticationService: UserService,
    private bnIdle: BnNgIdleService
  ) {
    this.authenticationService.currentUser.subscribe(x => this.currentUser = x);
  }

  ngOnInit(): void {
    this.bnIdle.startWatching(1200).subscribe((res) => {
      if(res) {
          console.log("Admin session expired");
          localStorage.removeItem("currentUser");
          this.router.navigate(['/']);
      }
    });
  }

  logout() {
    //this.authenticationService.logout();
    this.router.navigate(['/']);
  }
}
