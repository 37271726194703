import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css']
})
export class LandingPageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  onNavigate() {
    /*window.open("https://www.soilhealth.com/", "_blank");*/
    window.open('/PrivacyPolicy', '_blank').focus();
  }

}
