import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '../service/user.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [UserService, NgxSpinnerService]
})
export class HomeComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private service: UserService,
    private toastr: ToastrService,

  ) { }

  PDTopic: any = 0;
  PD: any = 0;
  ngOnInit() {
    this.spinner.show();
    this.service.getDashboard()
      .subscribe(
        data => {
          this.spinner.hide();
          if (data.responseCode == 200) {

            console.log(data.responseData);
            this.PD = data.responseData.podcastCount;
            this.PDTopic = data.responseData.podcastTopicCount;
          }
          else {
            this.spinner.hide();
            this.toastr.error(data.responseData);
          }

        },
        error => {
          // this.error = error;

        });
  }
}
