import { Injectable } from '@angular/core';
import { Component, Inject } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, config, throwError, Subject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { User } from '../_models/User';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  EndPoint: string;
  private currentUserSubject: Subject<User> = new Subject();
  public currentUser: Observable<User>;

  constructor(
    private http: HttpClient

  ) {

    //this.EndPoint = "https://localhost:44320";
    //this.EndPoint = "http://ngoappdemo.projectstatus.in";// ------- Use for Uat/demo
    this.EndPoint = "https://www.soilhealth.net.au";
    this.currentUser = this.currentUserSubject.asObservable();
  }


  public get currentUserValue(): any {
    return this.currentUserSubject.asObservable();
  }


  public currentSyncUser(): any {
    try {
      return JSON.parse(localStorage.getItem('currentUser'));
    } catch (e) {
      console.log(e);
    }
    return null;

  }


  login(param: any) {
    return this.http.post<any>(this.EndPoint + `/api/User/Login`, param)
      .pipe(
        map(VaidateUser => {
          console.log(VaidateUser);
          if (param.remember == true) {
            sessionStorage.setItem('Token', VaidateUser.responseData);
          }
          else {
            sessionStorage.removeItem('token');
          }
          let user = {
            token: VaidateUser.responseData
          };
          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(VaidateUser.responseData);

          return VaidateUser;

        }),
        catchError(this.handleError)
      );
  }

  forgotPassword(param: any) {
    return this.http.post<any>(this.EndPoint + `/api/User/ForgotPassword`, param)
      .pipe(
        map(response => {

          return response;
        }),
        catchError(this.handleError)
      );
  }

  resetPassword(param: any) {
    return this.http.post<any>(this.EndPoint + `/api/User/ResetPassword`, param)
      .pipe(
        map(response => {

          return response;
        }),
        catchError(this.handleError)
      );
  }

  changePassword(param: any) {
    return this.http.post<any>(this.EndPoint + `/api/User/ChangePassword`, param)
      .pipe(
        map(response => {

          return response;
        }),
        catchError(this.handleError)
      );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  getDashboard() {
    return this.http.post<any>(this.EndPoint + `/api/User/GetDashboard`, null)
      .pipe(
        map(response => {

          return response;
        }),
        catchError(this.handleError)
      );
  }

  createPodcastTopic(param: any) {
    return this.http.post<any>(this.EndPoint + `/api/User/CreatePodcastTopic`, param)
      .pipe(
        map(response => {

          return response;
        }),
        catchError(this.handleError)
      );
  }

  updatePodcastTopic(param: any) {
    return this.http.post<any>(this.EndPoint + `/api/User/UpdatePodcastTopic`, param)
      .pipe(
        map(response => {

          return response;
        }),
        catchError(this.handleError)
      );
  }

  updateDisplayOrder(param: any) {
    return this.http.post<any>(this.EndPoint + `/api/User/UpdateListDisplayOrder`, param)
      .pipe(
        map(response => {

          return response;
        }),
        catchError(this.handleError)
      );
  }

  getPodcastTopicList() {
    return this.http.post<any>(this.EndPoint + `/api/User/GetPodcastTopiclist`, null)
      .pipe(
        map(response => {

          return response;
        }),
        catchError(this.handleError)
      );
  }

  getPodcastTopicByID(param: any) {
    return this.http.post<any>(this.EndPoint + `/api/User/getPodcastTopicByID?id=` + param, null)
      .pipe(
        map(response => {

          return response;
        }),
        catchError(this.handleError)
      );
  }

  deletePodcastTopic(param: any) {
    return this.http.post<any>(this.EndPoint + `/api/User/DeletePodcastTopic?id=` + param, null)
      .pipe(
        map(response => {

          return response;
        }),
        catchError(this.handleError)
      );
  }

  createPodcast(param) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' })
    }
    return this.http.post<any>(this.EndPoint + `/api/User/AddPodcast`, param)
      .pipe(
        map(response => {

          return response;
        }),
        catchError(this.handleError)
      );
  }

  //createPodcast(data) {
  //  const httpOptions = {
  //    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  //  }

  //  const url = this.EndPoint + `/api/User/AddPodcast`;
  //  //let input = new FormData();
  //  //input.append('podcast', data);   // "url" as the key and "data" as value
  //  return this.http.post(url, data, httpOptions).pipe(map((resp: any) => resp));
  //}

  updatePodcast(param: any) {
    return this.http.post<any>(this.EndPoint + `/api/User/UpdatePodcast`, param)
      .pipe(
        map(response => {

          return response;
        }),
        catchError(this.handleError)
      );
  }


  updatePodcastDisplayOrder(param: any) {
    return this.http.post<any>(this.EndPoint + `/api/User/updatePodcastDisplayOrder`, param)
      .pipe(
        map(response => {

          return response;
        }),
        catchError(this.handleError)
      );
  }

  getPodcastList() {
    return this.http.post<any>(this.EndPoint + `/api/User/GetAllPodcast`, null)
      .pipe(
        map(response => {

          return response;
        }),
        catchError(this.handleError)
      );
  }

  getPodcastByID(param: any) {
    return this.http.post<any>(this.EndPoint + `/api/User/GetPodcastByID?id=` + param, null)
      .pipe(
        map(response => {

          return response;
        }),
        catchError(this.handleError)
      );
  }

  deletePodcast(param: any) {
    return this.http.post<any>(this.EndPoint + `/api/User/DeletePodcast?id=` + param, null)
      .pipe(
        map(response => {

          return response;
        }),
        catchError(this.handleError)
      );
  }



  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }
}
