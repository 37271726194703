import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../service/user.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private authenticationService: UserService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const currentUser = this.authenticationService.currentSyncUser();
    if (currentUser) {
      if (currentUser.token != 'Password invalid') {// logged in so return true
        return true;
      }
      else {
        return false;
      }
    }
    else {
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/']);
      return false;
    }


  }
}
